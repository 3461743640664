import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import style from '../css/components-css/layout.module.sass'

import Header from './Header'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: 'Pietro Cau. Personal site and blog' },
            { name: 'keywords', content: 'Pietro, Cau, design, blog, code' },
          ]}
        >
          <html lang="en" />
          <title>{data.site.siteMetadata.title}</title>
          <meta charset="UTF-8"/>
          <meta name="description" content="Pietro Cau. Personal site and blog"/>
          <meta name="keywords" content="Pietro, Cau, design, blog, code"/>
          <meta name="author" content="Pietro Cau"/>
        </Helmet>
        <Header/>
        <div>
          {children}
        </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
