import React, {Component} from "react"
import { Link } from 'gatsby'

import Layout from "../../../components/Layout"

import "../../../css/main.sass"
import style from "../../../css/pages-css/article-default.module.sass"

class Article extends Component{

  render(){
    return (
      <Layout>
      <main>
        <section className={style.topSection}>
        </section>
        <section className={style.contentSection}>
          <article>
            <h1>Hello World</h1>
            <p className={style.textBlock}>Hi, this is my first post on this blog, and if you are reading this, then it means that I managed to get the site working.<br/>
              I built it using GatsbyJS and React and I will slowly update it as time goes on.<br/>
              If you want some more context about me and the site, check out the <Link to="/about">About page</Link>.</p>
            <p className={style.textBlock}>As far as content goes I think I'll be posting every Monday at about 18:00 CET and maybe I'll get some minor posts out along the week.<br/>
              I guess you can come back next Monday to see if I made it (no guarantees), or check again through the week.<br/>
              I'll try my best to be consistent with it.</p>
            <p className={style.textBlock}>What led me to open this website is a combination of factors. 
              First of all, I wanted a portfolio website, where I could collect and put on display all the small and big projects I work on, but not only that, also everything I create that I think it's worth of notice, say an app, a video, a design for a t-shirt, whatever. So I'll do that, and I'll also go through the process I used to create whatever it is that I posted about and I'll critique it as well. Obviously, every feedback is welcome (I'll try to implement some way of commenting posts as soon as possible).<br/>
              Secondly, I wanted to post about ideas and opinions I have, on whatever subject, in order to explore them better myself and to learn something doing it. Hopefully, they will have the same effect on you and we will both get something out of it.</p>
            <p className={style.textBlock}>Anyhow, the journey begins!<br/>
              Are you along for the ride?</p>
          </article>
        </section>
      </main>
      </Layout>
    )
  }

}

export default Article